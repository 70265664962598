import { mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';
import DentallySvg from '$resources/dentally.svg';
import { ResultItem } from '../ResultItem';
import { Icon } from '$ui/Flo/Icon';
import { LinkBox } from '.';
import { Match } from '$api/dentally/match';
import { lang } from '$utils';
import { PatientDetails, PatientFinancials } from '$state/concerns/dentally/patients';

interface Props {
    id: string; // patient_id
    query: string;
    onChange?: (query: string) => void;
    onClear?: () => void;
    onLink?: (patient_id: string, dentally_id: string) => void;
    onUnlink?: (patient_id: string) => void;
    onView?: (dentally_id: string) => void;
}

interface LoadingProps extends Props {
    state: 'loading';
}

interface LinkingStateProps extends Props {
    state: 'linking';
}

interface FoundProps extends Props {
    state: 'found';
    patients: Match[];
}

interface SuggestionsProps extends Props {
    state: 'suggest';
    patients: Match[];
}

interface ErrorProps extends Props {
    state: 'error';
}

interface LinkedProps extends Props {
    state: 'linked';
    patient: PatientDetails;
    financials: PatientFinancials;
    currency?: string;
}

/**
 * Linking component
 * let parent component handle the incoming state
 * and pass the props to this componentStates are:
 * - loading
 * - found
 * - suggest
 * - error
 * - linked
 *

 */

export type LinkingProps =
    | LoadingProps
    | LinkingStateProps
    | FoundProps
    | SuggestionsProps
    | ErrorProps
    | LinkedProps; // union props

export const Linking = (props: LinkingProps) => {
    const { state, query, onChange, onClear, onLink, onUnlink, onView } = props;

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.value);
    };

    const onSearchClear = () => {
        onClear?.();
    };

    if (state === 'linking') {
        return <LinkBox onUnlink={() => null} onView={() => null} />;
    }

    if (state === 'linked') {
        return (
            <LinkBox
                patient={props.patient}
                financials={props.financials}
                onUnlink={() => onUnlink && onUnlink(props.id)}
                onView={() => onView && onView(props.patient.id.toString())}
            />
        );
    }

    const caseSuggestZero = state === 'suggest' && props.patients.length === 0;

    return (
        <Wrapper>
            {state === 'suggest' && (
                <Header>
                    <Title>{props.patients.length} Suggested matches</Title>
                    <Text>
                        We found {props.patients.length} potential matching
                        patients in Dentally, click to link the right one
                    </Text>
                </Header>
            )}
            {!caseSuggestZero && (
                <Result>
                    {state === 'error' && <NotFound />}

                    {state === 'loading' && <Loading />}

                    {state === 'found' &&
                        props.patients.map((patient) => (
                            <ResultItem
                                patient={patient}
                                query={query}
                                key={patient.id}
                                onLink={(dentallyId) =>
                                    onLink?.(props.id, dentallyId)
                                }
                                onView={(dentallyId) => onView?.(dentallyId)}
                            />
                        ))}

                    {
                        //sort by score
                        state === 'suggest' &&
                            props.patients &&
                            !lang.isEmpty(props.patients) &&
                            props.patients.map((patient) => (
                                <ResultItem
                                    patient={patient}
                                    score={
                                        patient.confidence !== 0
                                            ? patient.confidence
                                            : undefined
                                    } // conditional inclusion of score prop
                                    query={query}
                                    key={patient.id}
                                    onLink={(dentallyId) =>
                                        onLink?.(props.id, dentallyId)
                                    }
                                    onView={(dentallyId) =>
                                        onView?.(dentallyId)
                                    }
                                    bold={true}
                                />
                            ))
                    }
                </Result>
            )}
            <Search>
                {state === 'suggest' && !lang.isEmpty(props.patients) && (
                    <Info>None of these patients match?</Info>
                )}

                <InputGroup>
                    <Input
                        data-testid="dentally-search-input"
                        placeholder={
                            state === 'suggest' && !lang.isEmpty(props.patients)
                                ? 'Try searching for more here...'
                                : 'Search for patients in Dentally...'
                        }
                        value={query}
                        onChange={onSearchChange}
                    />
                    <ClearButton
                        data-testid="dentally-search-clear"
                        onClick={() => onSearchClear()}
                        show={query.length !== 0}
                    >
                        <Icon icon="X" size={2} />
                    </ClearButton>
                </InputGroup>
            </Search>
            <Footer>
                <DentallyLogo />
            </Footer>
        </Wrapper>
    );
};

const NotFound = () => {
    return (
        <Header>
            <Title>No patients found</Title>
            <Text>
                We couldn't find any potential matching patients. You can try
                searching for them below using any of their details
            </Text>
        </Header>
    );
};

const Loading = React.memo(() => {
    return <ResultItem loading />;
});

const Info = styled.div`
    ${mix.type({ level: 'body2', bold: true })};
    ${mix.gap({ size: 0.5 })};
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 1.25 })}
`;

const Title = styled.div`
    ${mix.type({ level: 'body1', bold: true })}
`;

const Text = styled.div<{ bold?: boolean }>`
    ${({ bold }) => css`
        ${mix.type({ level: 'body2', bold: bold ? true : false })}
    `}
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${mix.gap({ size: 1 })}
`;

const Search = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    ${mix.bg({ hue: 'grey', shade: '9', alpha: 0.25 })}
`;

const Input = styled.input`
    ${mix.type({ level: 'body2' })}
    ${mix.color({ profile: 'body' })}
    padding: 0;
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    position: relative;
`;

const ClearButton = styled.div<{ show: boolean }>`
    display: flex;
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
    align-items: center;
    justify-content: center;
    ${mix.bg({ hue: 'grey', shade: '9' })}
    ${mix.padding({ padding: [1] })}
    ${mix.margin({ margin: [0, 0, 0, 0.5] })}
    border-radius: 50%;
    cursor: pointer !important;

    & > * {
        width: 100%;
        height: auto;
        cursor: pointer !important;
    }
`;

const RowStyle = css`
    display: flex;
    flex-direction: row;
`;

const ColumnStyle = css`
    display: flex;
    flex-direction: column;
`;

const HorizontalLineStyle = css`
    ${mix.padding({ padding: [1, 0, 1] })}
    border: 1px solid ${mix.palette({
        hue: 'grey',
        shade: '9',
    })};
    border-bottom: none;
`;

const Wrapper = styled.div`
    ${mix.type({ level: 'body2' })}
    ${mix.color({ profile: 'body' })}
    ${ColumnStyle};
    width: 100%;
    position: relative;

    & > div {
        ${mix.padding({ padding: [3] })}
    }

    & > div {
        border: 1px solid
            ${mix.palette({
                hue: 'grey',
                shade: '10',
            })};
        border-bottom: none;
    }

    & > div:last-child {
        border: 1px solid
            ${mix.palette({
                hue: 'grey',
                shade: '10',
            })};
    }
`;

const Result = styled.div`
    ${HorizontalLineStyle};
    ${ColumnStyle};
    ${mix.gap({ size: 2 })};
    max-height: 400px;
    overflow-y: auto;

    & > div {
        ${mix.padding({ padding: [2, 0] })}
    }

    & > div:first-child {
        ${mix.padding({ padding: [0, 0, 2, 0] })}
    }

    & > div:last-child {
        ${mix.padding({ padding: [2, 0, 0, 0] })}
        border-bottom: 1px solid transparent;
    }
`;

const Footer = styled.div`
    ${RowStyle};
    ${mix.gap({ size: 1 })}
    ${HorizontalLineStyle};
    justify-content: space-between;
    align-items: center;
    height: auto;

    & > svg {
        height: auto;
        margin-left: auto;
    }
`;

const DentallyLogo = styled(DentallySvg)`
    ${mix.height({ size: 8 })}
    ${mix.fill({ hue: 'primary', shade: '6' })}
    align-self: flex-end;
`;
