import { obj, str } from '$utils';
import {
    createAsyncThunk,
    createReducer,
    createSelector
} from '@reduxjs/toolkit';
import z from 'zod';
import { api } from '$api';
import { RootState } from '$state';
import { withState } from '$state/utils';
import { featureEnabled } from '$state/queries/features';
import { integrationHealthy } from '$state/concerns/integrations';

// types

// This boilerplate converts snake_case keys to camelCase using Zod transforms.
// This could be generalised in the future to allow JSON responses to be in
// snake case and converted into camel case automatically.
//
// For requests, it would work in a similar way but in reverse.
//
// Doing this on the API would be a blessing.

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
type Json = Literal | { [key: string]: Json } | Json[];
const jsonSchema: z.ZodType<Json> = z.lazy(() =>
    z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
);

const transformKeys = (x: Json): Json => {
    if (typeof x !== 'object' || x === null) {
        return x;
    }

    if (Array.isArray(x)) {
        return x.map(transformKeys);
    }

    const camelCased = obj.mapKeys(x, str.camel);

    return obj.mapValues(camelCased, transformKeys);
};

const fromSnakeCase = () => jsonSchema.transform(transformKeys);

const PatientDetailsSchema = fromSnakeCase().pipe(
    z.object({
        id: z.number(),
        firstName: z.string(),
        lastName: z.string(),
        middleName: z.string().nullable(),
        emailAddress: z.string().email().nullable(),
        dateOfBirth: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
        phone: z.string().nullable()
    })
);

export type PatientDetails = z.infer<typeof PatientDetailsSchema>;

const InvoiceSchema = z.object({
    id: z.number(),
    date: z.string(),
    amount: z.string()
});

const TreatmentSchema = z.object({
    id: z.number(),
    name: z.string(),
    amount: z.string(),
    quantity: z.number()
});

const PatientFinancialsSchema = fromSnakeCase().pipe(
    z.object({
        dentallyId: z.number(),

        invoices: z.object({
            unpaid: z.array(InvoiceSchema),
            paid: z.array(InvoiceSchema)
        }),

        uninvoicedTreatments: z.array(TreatmentSchema),

        total: z.object({
            unpaid: z.string(),
            paid: z.string()
        })
    })
);

export type PatientFinancials = z.infer<typeof PatientFinancialsSchema>;

const SyncingStateSchema = z.object({
    state: z.literal('syncing'),
    details: PatientDetailsSchema
});

type SyncingState = z.infer<typeof SyncingStateSchema>;

const SyncedStateSchema = z.object({
    state: z.literal('synced'),
    details: PatientDetailsSchema,
    financials: PatientFinancialsSchema
});

export type SyncedState = z.infer<typeof SyncedStateSchema>;

export const PatientStateSchema = z.discriminatedUnion('state', [
    z.object({ state: z.literal('unlinked') }),
    SyncingStateSchema,
    SyncedStateSchema
]);

type DisabledState = { state: 'disabled' };
type IdleState = { state: 'idle' };
type LoadingState = { state: 'loading' };
type LinkingState = { state: 'linking' };

export type LinkedState = SyncingState | SyncedState;

export type PatientLinkState = z.infer<typeof PatientStateSchema>;

export type PatientState =
    | DisabledState
    | IdleState
    | LoadingState
    | LinkingState
    | { state: 'error'; error: string }
    | PatientLinkState;

interface PatientStateMap {
    [id: string]: PatientState;
}

// State

export const get = createAsyncThunk<PatientState, string>(
    'dentally/patients/get',
    (id: string, ThunkAPI) => {
        const state = ThunkAPI.getState() as RootState;
        const enabled = featureEnabled(state, 'dentally');
        const healthy = integrationHealthy(state, 'dentally');

        if (!enabled || !healthy) {
            return Promise.resolve({ state: 'disabled' });
        }

        return api
            .get(`/dentally/patients/${id}`)
            .then(({ data }) => PatientStateSchema.parse(data));
    }
);

interface LinkArgs {
    patientId: string;
    dentallyId: number;
}

export const link = createAsyncThunk<void, LinkArgs>(
    'dentally/patients/link',
    async (args, ThunkAPI) => {
        await api.post('/dentally/links', {
            patient_id: args.patientId,
            id: args.dentallyId
        });

        await ThunkAPI.dispatch(get(args.patientId));
    }
);

export const unlink = createAsyncThunk<void, string>(
    'dentally/patients/unlink',
    async (id: string) => {
        await api.delete(`/dentally/links/${id}`);
    }
);

const initialState: PatientStateMap = {};

export default createReducer(initialState, (builder) => {
    builder.addCase(get.pending, (state, action) => {
        if (state[action.meta.arg]?.state !== 'idle') {
            return;
        }

        state[action.meta.arg] = { state: 'loading' };
    });

    builder.addCase(get.fulfilled, (state, action) => {
        state[action.meta.arg] = action.payload;
    });

    builder.addCase(get.rejected, (state, action) => {
        state[action.meta.arg] = {
            state: 'error',
            error: action.error.message ?? 'Unknown error'
        };
    });

    builder.addCase(link.pending, (state, action) => {
        state[action.meta.arg.patientId] = { state: 'linking' };
    });

    builder.addCase(unlink.pending, (state, action) => {
        state[action.meta.arg] = { state: 'unlinked' };
    });
});

// Selectors

const selectState = (state: RootState) => state.dentally.patients;

export const selectPatient = createSelector(
    [
        selectState,
        (_: RootState, id: string) => id,
        withState(featureEnabled, 'dentally'),
        withState(integrationHealthy, 'dentally')
    ],
    (state, id, enabled, healthy) => {
        if (!enabled || !healthy) {
            return { state: 'disabled' } as const;
        }

        return state[id] ?? ({ state: 'idle' } as const);
    }
);

export const disabled = (state: PatientState): state is DisabledState =>
    state.state === 'disabled';

export const idle = (state: PatientState): state is IdleState =>
    state.state === 'idle';

export const unlinked = (state: PatientState): state is { state: 'unlinked' } =>
    state.state === 'unlinked';

export const loading = (state: PatientState): state is LoadingState =>
    state.state === 'loading';

export const linking = (state: PatientState): state is LinkingState =>
    state.state === 'linking';

export const linked = (state: PatientState): state is LinkedState =>
    state.state === 'syncing' || state.state === 'synced';

export const syncing = (state: PatientState): state is SyncingState =>
    state.state === 'syncing';

export const synced = (state: PatientState): state is SyncedState =>
    state.state === 'synced';

export const details = ({ details }: LinkedState) => details;

export const financials = ({ financials }: SyncedState) => financials;
